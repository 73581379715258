<template>
  <div class="card" :class="`bg-${color} text-white`">
    <div class="card-body d-flex align-items-center p-3">
      <div class="mr-2 text-white p-3">
        <slot></slot>
      </div>
      <div>
        <div v-if="header" class="text-value-lg">{{header}}</div>
        <div v-if="text">{{text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CWidgetIcon',
  props: {
    header: String,
    text: String,
    color: String
  }
}
</script>
