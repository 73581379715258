<template>
  <div>
    <CModal title="System Message" :color="color" :show.sync="alert.message!=null" v-on:okClicked="backBtn" v-on:closeClicked="backBtn" :closeOnBackdrop="false" :showCancel="false" >
        <div class="my-3 row">
            <div class="form-group col-lg-12">
                {{alert.message}}
            </div>
        </div>
    </CModal>
  </div>
</template>

<script>
import CModal from './CModal'

export default {
  name: 'CNotification',
  components: { CModal },
  computed: {
    alert () {
        return this.$store.state.alert
    },
    color () {
      if (this.alert.type == 'danger') {
        return 'danger'
      } else {
        return 'success'
      }
    }
  },
  methods: {
    backBtn () {
      if (this.alert.type != 'danger') {
        this.$store.dispatch('alert/clear');
        const loggedIn = localStorage.getItem('user')
        const stayPages = /(test|settings|system-infra|queue|risk-management)/
        const stay = stayPages.test(this.$route.path)

        if(!loggedIn) {
          this.$router.push('/login')
        } else if(!stay) {
          history.back();
        }
      } else {
        this.$store.dispatch('alert/clear');
      }
    }
  }
}
</script>