import Vue from 'vue';
import Vuex from 'vuex';

import alert from '@/store/alert';
import authentication from './authentication';
import users from './users';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        alert,
        authentication,
        users
    }
});

export default store
