import router from '@/router/index'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null }

const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ commit }, { user, redirect }) {
      commit('loginSuccess', user)
      router.push(redirect)
      // commit('loginRequest', { username })

      // userService.login(username, password)
      //   .then(
      //     user => {
      //       commit('loginSuccess', user)
      //       router.push(redirect)
      //     },
      //     error => {
      //       commit('loginFailure', error)
      //       dispatch('alert/error', error, { root: true })
      //     }
      //   )
    },
    logout ({ commit }) {
      localStorage.clear()
      commit('logout')
    }
  },
  mutations: {
    loginRequest (state, user) {
      state.status = { loggingIn: true }
      state.user = user
    },
    loginSuccess (state, user) {
      state.status = { loggedIn: true }
      state.user = user
    },
    loginFailure (state) {
      state.status = {}
      state.user = null
    },
    logout (state) {
      state.status = {}
      state.user = null
    }
  }
}

export default authentication
