<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';

</style>

<style>
@font-face {
  font-family: "Barlow";
  src: url('./assets/fonts/Barlow-Regular.ttf');
}
@font-face {
  font-family: "CreditCard";
  src: url('./assets/fonts/credit-card-3.ttf');
}

body {
  font-family: 'Barlow',sans-serif;
  font-weight: 400;
  font-size:16px;
  background-color:white;
}
</style>

<script>
export default {
    name: 'app'
};
</script>
