import Vue from 'vue'
import VueRouter from 'vue-router'
import Container from '@/views/layouts/Container.vue'

Vue.use(VueRouter)

function lazyLoad (view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: Container,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: lazyLoad('Dashboard')
      },
      {
        path: 'tokens',
        name: 'Token Management',
        redirect: '/tokens',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/tokens',
            name: 'Tokens',
            meta: {permission: 'token_view'},
            component: lazyLoad('tokens/Index')
          },
          {
            path: '/tokens/transaction',
            name: 'Token Transactions',
            meta: {permission: 'token_view_trans'},
            component: lazyLoad('tokens/TokenTransaction')
          },
          {
            path: '/tokens/event',
            name: 'Token Events',
            meta: {permission: 'token_view_event'},
            component: lazyLoad('tokens/TokenEvents')
          },
          {
            path: '/token/:uuid',
            name: 'Token Detail',
            meta: {permission: 'token_view'},
            component: lazyLoad('tokens/Detail')
          },
          {
            path: '/de-token',
            name: 'DeToken',
            meta: {permission: 'token_detokenize'},
            component: lazyLoad('tokens/DeToken')
          }
        ]
      },
      {
        path: 'cards',
        name: 'Card Management',
        redirect: '/cards',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/cards',
            name: 'Cards',
            meta: {permission: 'card_view'},
            component: lazyLoad('cards/Index')
          },
          {
            path: '/card/:uuid',
            name: 'Card Detail',
            meta: {permission: 'card_view'},
            component: lazyLoad('cards/Detail')
          },
          {
            path: '/cards/event',
            name: 'Card Events',
            meta: {permission: 'card_view_event'},
            component: lazyLoad('cards/CardEvents')
          }
        ]
      },
      {
        path: 'issuers',
        name: 'Issuer Management',
        redirect: '/issuers',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/issuers',
            name: 'Issuers',
            meta: {permission: 'issuer_view'},
            component: lazyLoad('issuers/Index')
          },
          {
            path: '/issuer/new',
            name: 'Add New Issuer',
            meta: {permission: 'issuer_create'},
            component: lazyLoad('issuers/Create')
          },
          {
            path: '/issuer/profile',
            name: 'Issuer Detail',
            meta: {permission: 'issuer_profile_view'},
            component: lazyLoad('issuers/Profile')
          },
          {
            path: '/issuer/profile/edit',
            name: 'Edit Issuer Detail',
            meta: {permission: 'issuer_profile_update'},
            component: lazyLoad('issuers/EditProfile')
          },
          {
            path: '/issuer/profile/keys',
            name: 'Issuer API Keys',
            meta: {permission: 'issuer_api_view'},
            component: lazyLoad('issuers/OwnKey')
          },
          {
            path: '/issuer/:uuid/keys',
            name: 'Issuer API Keys',
            meta: {permission: 'issuer_api_view'},
            component: lazyLoad('issuers/Key')
          },
          {
            path: '/issuer/:uuid/edit',
            name: 'Edit Issuer',
            meta: {permission: 'issuer_update'},
            component: lazyLoad('issuers/Edit')
          },
          {
            path: '/issuer/:uuid',
            name: 'Issuer Detail',
            meta: {permission: 'issuer_view'},
            component: lazyLoad('issuers/Detail')
          },
          {
            path: '/bins',
            name: 'BIN Ranges',
            meta: {permission: 'bin_view'},
            component: lazyLoad('bins/Index')
          },
          {
            path: '/bin/new',
            name: 'Add New BIN Range',
            meta: {permission: 'bin_create'},
            component: lazyLoad('bins/Create')
          },
          {
            path: '/issuer-bin/:uuid',
            name: 'BIN Range Detail',
            meta: {permission: 'bin_view'},
            component: lazyLoad('bins/IssuerBins')
          },
          {
            path: '/bin/:uuid',
            name: 'BIN Range Detail',
            meta: {permission: 'bin_view'},
            component: lazyLoad('bins/Detail')
          },
          {
            path: '/bin/:uuid/edit',
            name: 'Edit BIN Range',
            meta: {permission: 'bin_update'},
            component: lazyLoad('bins/Edit')
          }
        ]
      },
      {
        path: '/merchant/profile',
        name: 'Token Requestor Detail',
        meta: {permission: 'tr_profile_view'},
        component: lazyLoad('merchants/Profile')
      },
      {
        path: '/merchant/profile/edit',
        name: 'Edit Token Requestor Detail',
        meta: {permission: 'tr_profile_update'},
        component: lazyLoad('merchants/EditProfile')
      },
      {
        path: 'merchants',
        name: 'Token Requestor Management',
        redirect: '/merchants',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/merchants',
            name: 'Token Requestors',
            meta: {permission: 'tr_view'},
            component: lazyLoad('merchants/Index')
          },
          {
            path: '/merchant/new',
            name: 'Add New Token Requestor',
            meta: {permission: 'tr_create'},
            component: lazyLoad('merchants/Create')
          },
          {
            path: '/merchant/profile/keys',
            name: 'Token Requestor API Keys',
            meta: {permission: 'tr_api_view'},
            component: lazyLoad('merchants/OwnKey')
          },
          {
            path: '/merchant/:uuid/keys',
            name: 'Token Requestor API Keys',
            meta: {permission: 'tr_api_view'},
            component: lazyLoad('merchants/Key')
          },
          {
            path: '/merchant/:uuid/edit',
            name: 'Edit Token Requestor',
            meta: {permission: 'tr_update'},
            component: lazyLoad('merchants/Edit')
          },
          {
            path: '/merchant/:uuid',
            name: 'Token Requestor Detail',
            meta: {permission: 'tr_view'},
            component: lazyLoad('merchants/Detail')
          }
        ]
      },
      {
        path: 'users',
        name: 'Users',
        redirect: '/users',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/users',
            name: '',
            meta: {permission: 'access_view'},
            component: lazyLoad('users/Index')
          },
          {
            path: '/user/new',
            name: 'Add New User',
            meta: {permission: 'access_create'},
            component: lazyLoad('users/Create')
          },
          {
            path: '/user/:id/edit',
            name: 'Edit User',
            meta: {permission: 'access_update'},
            component: lazyLoad('users/Edit')
          },
          {
            path: '/user/:id',
            name: 'User Detail',
            meta: {permission: 'access_view'},
            component: lazyLoad('users/Detail')
          }
        ]
      },
      {
        path: 'roles',
        name: 'Roles',
        redirect: '/roles',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/roles',
            name: '',
            meta: {permission: 'access_view'},
            component: lazyLoad('roles/Index')
          },
          {
            path: '/role/new',
            name: 'Add New Role',
            meta: {permission: 'access_create'},
            component: lazyLoad('roles/Create')
          },
          {
            path: '/role/:id/edit',
            name: 'Edit Role',
            meta: {permission: 'access_update'},
            component: lazyLoad('roles/Edit')
          },
          {
            path: '/role/:id',
            name: 'Role Detail',
            meta: {permission: 'access_view'},
            component: lazyLoad('roles/Detail')
          }
        ]
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: {permission: 'system_view'},
        component: lazyLoad('systems/Settings')
      },
      {
        path: 'risk-management',
        name: 'Risk Management',
        meta: {permission: 'risk_view'},
        component: lazyLoad('systems/RiskManagement')
      },
      {
        path: 'hosts',
        name: 'Host Management',
        redirect: '/hosts',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/hosts',
            name: 'Hosts',
            meta: {permission: 'host_view'},
            component: lazyLoad('hosts/Index')
          },
          {
            path: '/host/new',
            name: 'Add New Host',
            meta: {permission: 'host_create'},
            component: lazyLoad('hosts/Create')
          },
          {
            path: '/host/:id',
            name: 'Host Detail',
            meta: {permission: 'host_view'},
            component: lazyLoad('hosts/Detail')
          },
          {
            path: '/host/:id/edit',
            name: 'Edit Host',
            meta: {permission: 'host_view'},
            component: lazyLoad('hosts/Edit')
          }
        ]
      },
      {
        path: 'data',
        name: 'Export Data',
        meta: {permission: 'data_export'},
        component: lazyLoad('data/Export')
      },
      {
        path: 'system-infra',
        name: 'System Infrastructure',
        meta: {permission: 'system_infra'},
        component: lazyLoad('systems/Infra')
      },
      {
        path: '/queue/:id',
        name: 'Queue Messages',
        meta: {permission: 'system_infra'},
        component: lazyLoad('systems/QueueMessages')
      },
      {
        path: 'logs',
        name: 'Logs',
        redirect: '/audit-logs',
        meta: { permission: 'audit_view' },
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/audit-logs',
            name: 'Audit Logs',
            meta: { permission: 'audit_view' },
            component: lazyLoad('systems/AuditLogs')
          }
        ]
      },
      {
        path: 'task-history',
        name: 'Tasks',
        redirect: '/task-history',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/task-history',
            name: 'Task History',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('systems/TaskHistory')
          },
          {
            path: '/task-suspend/:id',
            name: 'Request To Suspend',
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-delete/:id',
            name: 'Request To Delete',
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-reset/:id',
            name: 'Request To Reset',
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-reactivate/:id',
            name: 'Request To Reactive',
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-configure/:id',
            name: 'Request To Change',
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-api/:id',
            name: 'Request To Update API',
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-bin/:id',
            name: 'BIN Range Amendment',
            component: lazyLoad('tasks/BINView')
          },
          {
            path: '/task-create/:id',
            name: 'Request To Add',
            component: lazyLoad('tasks/CreateView')
          },
          {
            path: '/task-update/:id',
            name: 'Request To Update',
            component: lazyLoad('tasks/EditView')
          },
          {
            path: '/task-role/:id',
            name: 'Request To Update Role',
            component: lazyLoad('tasks/RoleView')
          }
        ]
      },
      {
        path: 'test',
        name: 'API Integration',
        redirect: '/test',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/test',
            name: 'API Test Results',
            meta: {permission: 'test_view'},
            component: lazyLoad('test/Index')
          },
          {
            path: '/test/submit',
            name: 'Submit API Test Result',
            meta: {permission: 'test_update'},
            component: lazyLoad('test/Edit')
          },
          {
            path: '/test/:id',
            name: 'Review API Test Result',
            meta: {permission: 'test_view'},
            component: lazyLoad('test/Detail')
          },
        ]
      },
      {
        path: 'test-tool',
        name: 'Test Tools',
        redirect: '/test-tool/token-provision',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/test-tool/token-provision',
            name: 'Token Provision',
            meta: {permission: 'test_tool'},
            component: lazyLoad('test/TokenProvision')
          },
          {
            path: '/test-tool/data-encrypt',
            name: 'Data Encryption',
            meta: { permission: 'test_tool' },
            component: lazyLoad('test/DataEnc')
          },
        ]
      },
      {
        path: '/me',
        name: 'Account Detail',
        component: lazyLoad('users/Me')
      },
      {
        path: '/logout',
        name: 'Logout',
        component: lazyLoad('users/Logout')
      }
    ]
  },
  {
    path: '/collect/:id',
    name: 'Collect file',
    component: lazyLoad('data/Collect')
  },
  {
    path: '/login',
    name: 'Login',
    component: lazyLoad('Login')
  },
  {
    path: '/oauth',
    name: 'Oauth',
    component: lazyLoad('OauthPage')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: lazyLoad('ForgotPassword')
  },
  {
    path: '/reset-password/:id',
    name: 'Reset Password',
    component: lazyLoad('ResetPassword')
  },
  {
    path: '/401',
    name: 'Unauthorize',
    component: lazyLoad('errors/401')
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: lazyLoad('errors/403')
  },
  {
    path: '/500',
    name: 'Internal Server Error',
    component: lazyLoad('errors/500')
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: lazyLoad('errors/404')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = /(login|oauth|forgot-password|reset-password|collect|404|401|403|500|404)/
  const authRequired = !publicPages.test(to.path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn) {
    return next('/login')
  }
  let user =  JSON.parse(loggedIn);

  if(to.meta.permission != null){
    if(Array.isArray(to.meta.permission)) {
      let found = to.meta.permission.find(p => user.permissions.includes(p))

      if(!found) {
        return next('/403')
      }
    } else if(!user.permissions.includes(to.meta.permission)){
      return next('/403')
    }
  }

  next()
})

export default router
