// import {
//   cibFacebook,
//   cibTwitter,
//   cibLinkedin,
//   cibFlickr,
//   cibTumblr,
//   cibXing,
//   cibGithub,
//   cibStackOverflow,
//   cibYoutube,
//   cibDribbble,
//   cibInstagram,
//   cibPinterest,
//   cibVk,
//   cibYahoo,
//   cibBehance,
//   cibReddit,
//   cibVimeo,
//   cibCcMastercard,
//   cibCcVisa,
//   cibStripe,
//   cibPaypal,
//   cibGooglePay,
//   cibCcAmex
// } from '@coreui/icons'
// import {
//   cifUs,
//   cifBr,
//   cifIn,
//   cifFr,
//   cifEs,
//   cifPl
// } from '@coreui/icons'
import {
  // cilArrowRight,
  // cilBan,
  // cilBank,
  // cilBasket,
  // cilBell,
  // cilCalculator,
  cilCalendar,
  cilCart,
  // cilCloudDownload,
  // cilChartLine,
  // cilChartPie,
  // cilCheck,
  // cilChevronBottom,
  // cilChevronTop,
  cilCheckCircle,
  // cilCommentSquare,
  // cilCursor,
  cilCreditCard,
  // cilDrop,
  cilDollar,
  // cilEnvelopeClosed,
  // cilEnvelopeOpen,
  // cilEuro,
  // cilGlobeAlt,
  // cilGrid,
  // cilFile,
  cilFingerprint,
  // cilJustifyCenter,
  // cilLaptop,
  // cilLayers,
  // cilLightbulb,
  // cilList,
  // cilLocationPin,
  cilLockLocked,
  // cilMagnifyingGlass,
  // cilMoon,
  // cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSettings,
  // cilShieldAlt,
  cilSitemap,
  // cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  // cilStar,
  // cilTask,
  cilUser,
  // cilUserFemale,
  // cilUserFollow,
  // cilXCircle,
  cilHome,
  cilCaretBottom,
  cilCaretTop,
  cilMemory,
  cilAccountLogout,
  cilBank,
  cilClipboard,
  cilFilter,
  cilCode,
  cilDataTransferUp,
  cilX

} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    // cilArrowRight,
    // cilBan,
    // cilBank,
    // cilBasket,
    // cilBell,
    // cilCalculator,
    cilCalendar,
    cilCart,
    // cilCloudDownload,
    // cilChartLine,
    // cilChartPie,
    // cilCheck,
    // cilChevronBottom,
    // cilChevronTop,
    cilCheckCircle,
    // cilCommentSquare,
    // cilCursor,
    cilCreditCard,
    // cilDrop,
    cilDollar,
    // cilEnvelopeClosed,
    // cilEnvelopeOpen,
    // cilEuro,
    // cilGlobeAlt,
    // cilGrid,
    // cilFile,
    cilFingerprint,
    // cilJustifyCenter,
    // cilLaptop,
    // cilLayers,
    // cilLightbulb,
    // cilList,
    // cilLocationPin,
    cilLockLocked,
    // cilMagnifyingGlass,
    // cilMoon,
    // cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    // cilShieldAlt,
    cilSitemap,
    // cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    // cilStar,
    // cilTask,
    cilUser,
    // cilUserFemale,
    // cilUserFollow,
    // cilXCircle,
    cilHome,
    cilCaretBottom,
    cilCaretTop,
    cilMemory,
    cilAccountLogout,
    cilBank,
    cilClipboard,
    cilFilter,
    cilCode,
    cilDataTransferUp,
    cilX
  }
  // {
  //   cifUs,
  //   cifBr,
  //   cifIn,
  //   cifFr,
  //   cifEs,
  //   cifPl
  // },
  // {
  //   cibFacebook,
  //   cibTwitter,
  //   cibLinkedin,
  //   cibFlickr,
  //   cibTumblr,
  //   cibXing,
  //   cibGithub,
  //   cibStackOverflow,
  //   cibYoutube,
  //   cibDribbble,
  //   cibInstagram,
  //   cibPinterest,
  //   cibVk,
  //   cibYahoo,
  //   cibBehance,
  //   cibReddit,
  //   cibVimeo,
  //   cibCcMastercard,
  //   cibCcVisa,
  //   cibStripe,
  //   cibPaypal,
  //   cibGooglePay,
  //   cibCcAmex
  // }
)
