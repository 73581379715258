<template>
  <CFooter>
    <div class="ml-auto">
      <span class="mr-1">&copy; {{ new Date().getFullYear() }} <a style="text-decoration: none; color:#4f5d73; " href="https://www.paynet.my/" target="_blank">PayNet 200801035403 (836743-D)</a>. All Rights Reserved </span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
