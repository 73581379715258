<template>
  <div class="d-inline">
    <span v-if="isEmpty" class="aclass">
      {{emptyLabel}}
    </span>
    <span v-if="!isEmpty && oldVal != newVal" class="text-danger">
      <del>{{oldVal}}</del> {{newVal}}
    </span>
    <span v-else class="cclass">
      {{ newVal }}
    </span>
  </div>
</template>

<script>
  
export default {
  name: "CompareLabel",
  props: {
    oldVal: {
      type: String,
      default: ''
    },
    newVal: {
      type: String,
      default: ''
    },
    emptyLabel: {
      type: String,
      default: 'N/A'
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
  }
}
</script>