export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/',
        icon: 'cil-home'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Module'],
        permission: ['token_view', 'tr_view', 'tr_profile_view', 'card_view', 'issuer_view', 'issuer_profile_view']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Token Management',
        icon: 'cil-fingerprint',
        permission: 'token_view',
        items: [
          {
            name: 'Token List',
            to: '/tokens',
            icon: 'cil-fingerprint'
          },
          {
            name: 'Token Transactions',
            to: '/tokens/transaction',
            icon: 'cil-spreadsheet',
            permission: 'token_view_trans'
          },
          {
            name: 'Token Events',
            to: '/tokens/event',
            icon: 'cil-spreadsheet',
            permission: 'token_view_event'
          },
          {
            name: 'DeToken',
            to: '/de-token',
            icon: 'cil-fingerprint',
            permission: 'token_detokenize'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Card Management',
        route: '/cards',
        icon: 'cil-credit-card',
        permission: 'card_view',
        items: [
          {
            name: 'Card List',
            to: '/cards',
            icon: 'cil-credit-card'
          },
          {
            name: 'Card Events',
            to: '/cards/event',
            icon: 'cil-spreadsheet',
            permission: 'card_view_event'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Issuer Management',
        icon: 'cil-bank',
        route: '/issuers',
        permission: ['issuer_view', 'bin_view', 'issuer_profile_view'],
        items: [
          {
            name: 'Issuer List',
            to: '/issuers',
            icon: 'cil-bank',
            permission: 'issuer_view'
          },
          {
            name: 'Issuer Detail',
            to: '/issuer/profile',
            icon: 'cil-bank',
            permission: 'issuer_profile_view'
          },
          {
            name: 'BIN Ranges',
            to: '/bins',
            icon: 'cil-spreadsheet',
            permission: 'bin_view'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Token Requestor Management',
        to: '/merchants',
        permission: 'tr_view',
        icon: 'cil-cart'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Token Requestor Detail',
        to: '/merchant/profile',
        permission: 'tr_profile_view',
        icon: 'cil-cart'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Export Data',
        to: '/data',
        permission: 'data_export',
        icon: 'cil-spreadsheet'
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Test'],
        permission: ['test_view', 'test_update', 'test_tool']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'API Integration',
        icon: 'cil-clipboard',
        permission: ['test_view', 'test_update'],
        items: [
          {
            name: 'Submit API Test Result',
            to: '/test/submit',
            icon: 'cil-clipboard',
            permission: 'test_update'
          },
          {
            name: 'API Test Results',
            to: '/test',
            icon: 'cil-clipboard',
            permission: 'test_view'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Test Tools',
        icon: 'cil-speedometer',
        permission: 'test_tool',
        items: [
          {
            name: 'Token Provision',
            to: '/test-tool/token-provision',
            icon: 'cil-speedometer',
            permission: 'test_tool'
          },
          {
            name: 'Data Encryption',
            to: '/test-tool/data-encrypt',
            icon: 'cil-speedometer',
            permission: 'test_tool'
          }
        ]
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['System'],
        permission: ['system_view', 'risk_view', 'host_view', 'access_view', 'system_infra', 'audit_view', 'task_history_view', 'task_history_view_all']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Settings',
        to: '/settings',
        permission: 'system_view',
        icon: 'cil-settings'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Risk Management',
        to: '/risk-management',
        permission: 'risk_view',
        icon: 'cil-settings'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Host Management',
        to: '/hosts',
        icon: 'cil-memory',
        permission: 'host_view'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Access Control',
        route: '/users',
        icon: 'cil-people',
        permission: 'access_view',
        items: [
          {
            name: 'Users',
            to: '/users',
            icon: 'cil-people'
          },
          {
            name: 'Roles',
            to: '/roles',
            icon: 'cil-sitemap'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'System Infrastructure',
        to: '/system-infra',
        permission: 'system_infra',
        icon: 'cil-settings'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Logs',
        icon: 'cil-spreadsheet',
        permission: ['audit_view', 'task_history_view', 'task_history_view_all'],
        items: [
          {
            name: 'Audit Logs',
            to: '/audit-logs',
            permission: 'audit_view',
            icon: 'cil-spreadsheet'
          },
          {
            name: 'Task History',
            to: '/task-history',
            permission: ['task_history_view', 'task_history_view_all'],
            icon: 'cil-spreadsheet'
          }
        ]
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['My Profile']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Account Detail',
        to: '/me',
        icon: 'cil-user'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Logout',
        to: '/logout',
        icon: 'cil-AccountLogout'
      }
    ]
  }
]
