import { render, staticRenderFns } from "./CTable.vue?vue&type=template&id=3adc0344&scoped=true"
import script from "./CTable.vue?vue&type=script&lang=js"
export * from "./CTable.vue?vue&type=script&lang=js"
import style0 from "./CTable.vue?vue&type=style&index=0&id=3adc0344&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3adc0344",
  null
  
)

export default component.exports