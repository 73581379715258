<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="5">
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <ValidationObserver v-slot="{ invalid }">
              <form id="resetPassword">
                <h3>Greetings, {{displayName}}</h3>
                <p class="text-muted">For security purpose, please change your password before proceed.</p>
                <p class="text-muted">
                  Your new password must be 8 to 32 characters with combination of:
                  <ul>
                    <li>At least 1 uppercase alphabet</li>
                    <li>At least 1 lowercase alphabet</li>
                    <li>At least 1 number</li>
                    <li>At least 1 of these special characters .!#$%&@</li>
                  </ul>
                </p>
                <div class="form-group">
                  <label for="password" class="required-field">Your New Password</label>
                  <ValidationProvider rules="required|password" v-slot="{ errors }" name="Password">
                    <input type="password" class="form-control" v-model="new_password" maxlength="32">
                    <span class="text-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <label for="confirmedPassword" class="required-field">Re-type Password to confirm</label>
                  <ValidationProvider rules="required|password_confirm:@Password" v-slot="{ errors }" name="Password Confirmation">
                    <input type="password" class="form-control" v-model="confirmed_password" maxlength="32">
                    <span class="text-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                
                <div class="form-row">
                  <div class="form-group col-12">
                    <button type="button" class="btn btn-success btn-block" v-on:click="resetBtn" :disabled="invalid">Change Now</button>
                  </div>
                </div>
              </form>
              </ValidationObserver>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
    <CNotification />
  </CContainer>
</template>


<style>
  .login {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 10px;
  }
</style>

<script>
import service from '@/services/userService'
import { CNotification } from '@/views/components/index.js'

export default {
  data () {
    return {
        email: '',
        token: '',
        new_password: '',
        confirmed_password: '',
    }
  },
  components: {CNotification},
  computed: {
    alert () {
      return this.$store.state.alert
    },
    displayName () {
      return localStorage.name
    }
  },
  methods: {
    resetBtn () {
      service.firstLogin(this.new_password, this.confirmed_password)
        .then( () => {
          service.logout()
        })
    }
  } 
};
</script>