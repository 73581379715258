<template>
  <div class="c-app">
    <template v-if="first">
      <First />
    </template>
    <template v-else>
      <Sidebar/>
      <div class="c-wrapper">
        <Header/>
        <div class="c-body">
          <main class="c-main">
            <CContainer fluid>
              <transition name="fade">
                <router-view></router-view>
              </transition>
            </CContainer>
          </main>
        </div>
        <CNotification />
        <Footer/>
      </div>
    </template>
  </div>
</template>

<script>
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'
import First from './First'
import { CNotification } from '@/views/components/index.js'

export default {
  name: 'Container',
  components: {
    Sidebar,
    Header,
    Footer,
    First,
    CNotification
  },
  computed: {
    first() {
      const first = localStorage.reset_password
      if(first) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
