import apiHandler from '@/helpers/apiHandler'

const userService = {
  login,
  submitOtp,
  logout,
  getAll,
  create,
  find,
  newUser,
  suspend,
  remove,
  reactivate,
  update,
  allow,
  updateMyProfile,
  me,
  findCompanyName,
  firstLogin,
  refreshToken,
  getPgpKey,
  resendOtp,
  ssoLogin
}

function login (username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  }

  return fetch(apiHandler.host() + 'portal-auth-api/authenticate', requestOptions)
    .then(response => {
      return response.text().then(text => {
        const resp = text && JSON.parse(text)
        if (resp.status == 200) {
          return resp.data
        } else {
          return resp
        }
      })
    })
}

function ssoLogin (idToken, accessToken) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id_token: idToken, access_token:accessToken })
  }

  return fetch(apiHandler.host() + 'portal-auth-api/authenticate', requestOptions)
    .then(response => {
      return response.text().then(text => {
        const resp = text && JSON.parse(text)
        if (resp.status == 200) {
          return resp.data
        } else {
          return resp
        }
      })
    })
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        initSession(user)
      }

      return user
    })
}

function submitOtp (session, username, otp) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ session, username, otp })
  }

  return fetch(apiHandler.host() + 'portal-auth-api/authorise', requestOptions)
    .then(response => {
      return response.text().then(text => {
        const resp = text && JSON.parse(text)
        if (resp.status == 200) {
          return resp.data
        } else {
          return resp
        }
      })
    })
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        initSession(user)
      }

      return user
    })
}

function resendOtp (session, username) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ session, username })
  }

  return fetch(apiHandler.host() + 'portal-auth-api/resendOtp', requestOptions)
    .then(response => {
      return response.text().then(text => {
        const resp = text && JSON.parse(text)
        if (resp.status == 200) {
          return resp.data
        } else {
          return resp
        }
      })
    })
}

function initSession (user) {
  localStorage.setItem('user', JSON.stringify(user))

  for (var permission of user.permissions) {
    localStorage[permission] = true
  }

  localStorage.username = user.username
  localStorage.name = user.display_name
  localStorage.token = user.token
  localStorage.path = user.path
}

function can (permission) {
  return localStorage[permission]
}

function allow (permission) {
  if (permission == null) {
    return true
  } else if (Array.isArray(permission)) {
    for (var i = permission.length - 1; i >= 0; i--) {
      if (can(permission[i])) {
        return true
      }
    }
    return false
  } else if (can(permission)) {
    return true
  } else {
    return false
  }
}
function getAll () {
  return apiHandler.commonAPICall('users', '')
}

function logout () {
  // remove user from local storage to log user out
  localStorage.clear()
}

function find (id) {
  return callUserApi('find', id)
}

function me () {
  return apiHandler.commonAPICall('user/me', '')
}

function getPgpKey (id) {
  const requestBody = JSON.stringify({
    id
  })
  return apiHandler.commonAPICall('user/pgp-key', requestBody)
}

function create (model) {
  model = setPhone(model)
  const requestBody = JSON.stringify({
    user: model,
    new_password: model.password,
    confirmed_password: model.confirmed_password,
    pgp_key: model.pgp_key
  })

  return apiHandler.commonAPICall('user/add', requestBody)
}

function newUser () {
  return {
    id: 0,
    username: '',
    display_name: '',
    email: '',
    role_id: 0,
    password: '',
    phone: '',
    phoneNo1: '+60',
    phoneNo2: '',
    confirmed_password: '',
    company_id: null,
    user_type: '',
    role: {
      id: 0
    },
    pgp_key: '',
    pgp_fingerprint: ''
  }
}

function updateMyProfile (model) {
  model = setPhone(model)
  const requestBody = JSON.stringify({
    user: model,
    new_password: model.password,
    confirmed_password: model.confirmed_password,
    pgp_key: model.pgp_key
  })

  return apiHandler.commonAPICall('user/update-my-profile', requestBody)
}

function update (model) {
  model = setPhone(model)
  const requestBody = JSON.stringify({
    user: model,
    new_password: model.password,
    confirmed_password: model.confirmed_password,
    pgp_key: model.pgp_key
  })

  return apiHandler.commonAPICall('user/update', requestBody)
}

function suspend (id) {
  return callUserApi('suspend', id)
}

function reactivate (id) {
  return callUserApi('reactivate', id)
}

function remove (id) {
  return callUserApi('delete', id)
}

function callUserApi (path, id) {
  const requestBody = JSON.stringify({
    id
  })

  return apiHandler.commonAPICall('user/' + path, requestBody)
}

function findCompanyName (type, id) {
  const requestBody = JSON.stringify({
    requestor_id: id,
    type
  })
  return apiHandler.commonAPICall('user/company-name', requestBody)
}

function firstLogin (new_password, confirmed_password) {
  const requestBody = JSON.stringify({
    new_password,
    confirmed_password
  })
  return apiHandler.commonAPICall('first-login', requestBody)
}

function refreshToken () {
  const requestOptions = {
    headers: apiHandler.authHeader(),
    method: 'POST'
  }

  return fetch(
    apiHandler.host() + 'portal-auth-api/refresh-token',
    requestOptions
  ).then(apiHandler.handleResponse)
    .catch(apiHandler.handleException)
}

function setPhone (model) {
  model.phone = model.phoneNo1 + model.phoneNo2
  return model
}

export default userService
