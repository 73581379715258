import Vue from 'vue'
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate'
import { required, email, max, min, length, alpha, integer, alpha_spaces, alpha_num } from 'vee-validate/dist/rules'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', {
  ...required,
  message: '{_field_} is required'
})

extend('min', {
  ...min,
  message: 'The {_field_} must have at least {length} characters'
})

extend('max', {
  ...max,
  message: 'The {_field_} must not more than {length} characters'
})

extend('email', {
  ...email,
  message: 'Please enter a valid email address'
})

extend('length', {
  ...length,
  message: 'The {_field_} must be {length} characters'
})

extend('alpha', {
  ...alpha,
  message: '{_field_} only allow alphabet character'
})

extend('alpha_num', {
  ...alpha_num,
  message: '{_field_} only allow alphabet and numeric character'
})

extend('integer', {
  ...integer,
  message: '{_field_} only allow number'
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: '{_field_} only allow alphabet and whitespace character'
})

extend('password', password => {
  var minMaxLength = /^[\s\S]{8,32}$/

  var upper = /[A-Z]/

  var lower = /[a-z]/

  var number = /[0-9]/

  var special = /[.!#$%&@]/

  if (minMaxLength.test(password) &&
      upper.test(password) &&
      lower.test(password) &&
      number.test(password) &&
      special.test(password)
  ) {
    return true
  }

  return '{_field_} must be at least 8 characters with combination of upper case alphabet, lower case alphabet, number, and special character .!#$%&@'
})

extend('password_confirm', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match'
})

extend('selected', value => {
  if (value > 0) {
    return true
  }

  return 'Please select a {_field_} from the dropdown'
})

extend('roc', value => {
  var oldFormat = /^(19|20){1}[0-9]{2}0[1-6]{1}[0-9]{6}$/

  var newFormat = /^\d{1,8}-[a-zA-Z]{1}$/

  if (oldFormat.test(value) || newFormat.test(value)) {
    return true
  }

  return 'Invalid Company Registration Number format'
})

extend('phone', value => {
  var mobile = /^(1)[0-46-9]{1}[0-9]{7,8}$/

  var landline = /^[3-9]{1}[0-9]{7,8}$/

  if (mobile.test(value) || landline.test(value)) {
    return true
  }

  return 'Invalid Phone Number format'
})

extend('url', value => {
  var format = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

  if (format.test(value)) {
    return true
  }

  return 'Invalid URL format'
})

extend('name', value => {
  var format = /^[a-zA-Z\s-@/'.]+$/
  if (format.test(value)) {
    return true
  }

  return '{_field_} contains invalid character'
})

extend('bin', value => {
  var format = /^0/
  if (!format.test(value)) {
    return true
  }

  return 'Invalid BIN number'
})

extend('color', value => {
  var format = /^#[A-Fa-f0-9]{6}$/
  if (format.test(value)) {
    return true
  }

  return 'Invalid color code.'
})
