import store from '@/store'

const apiHandler = {
  handleResponse,
  logout,
  handleException,
  apiUrl,
  host,
  commonAPICall,
  authHeader
}

function authHeader () {
  let token = localStorage.token

  if (token) {
    return { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
  } else {
    return { 'Content-Type': 'application/json' }
  }
}

function host () {
  if(location.host.includes('portal')) {
    return process.env.VUE_APP_API_URL_PORTAL
  } else {
    return process.env.VUE_APP_API_URL
  }
}

function apiUrl () {
  return host() + localStorage.path + '/v1/'
}

function commonAPICall (path, requestBody) {
  const requestOptions = {
    headers: authHeader(),
    method: 'POST',
    body: requestBody
  }

  return fetch(
    apiUrl() + path,
    requestOptions
  ).then(apiHandler.handleResponse)
    .catch(apiHandler.handleException)
}

function logout () {
  // remove user from local storage to log user out
  localStorage.clear()
}

function handleResponse (response) {
  if (response.status == 200) {
    return response.text().then(text => {
      const resp = text && JSON.parse(text)

      if (resp.status == 200 && resp.message != '') {
        store.dispatch('alert/success', resp.message)
        return resp
      } else if (resp.status == 401) {
        location.href = '/401'
      } else if (resp.status == 403) {
        location.href = '/403'
      } else if (resp.status == 404) {
        location.href = '/404'
      } else if (resp.status != 200) {
        store.dispatch('alert/error', resp.message)
        return resp
      } else {
        return resp.data
      }
    })
  } else if (response.status == 403) {
    location.href = '/403'
  } else if (response.status == 500) {
    location.href = '/500'
  }
}

function handleException (e) {
  console.log('handle exception ', e)
  logout()
  location.reload(true)
}

export default apiHandler
