import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from '@/assets/icons/icons.js'
import './helpers/formValidation'

Vue.config.productionTip = false

Vue.use(CoreuiVue)

Vue.prototype.$scrollToTop = () => {
  try {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (e) {
    window.scrollTo(0, 0)
  }
}

new Vue({
  router,
  icons,
  store,
  render: h => h(App)
}).$mount('#app')
