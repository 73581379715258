import CChartLineSimple from './CChartLineSimple'
import CChartBarSimple from './CChartBarSimple'
import CModal from './CModal'
import CSwitch from './CSwitch'
import CompareLabel from './CompareLabel'
import CDataTable from './CDataTable'
import CTable from './CTable'
import CNotification from './CNotification'
import CWidgetIcon from './CWidgetIcon'

export {
  CChartLineSimple,
  CChartBarSimple,
  CModal,
  CSwitch,
  CompareLabel,
  CDataTable,
  CTable,
  CNotification,
  CWidgetIcon
}
